
import { defineComponent, onMounted, ref, getCurrentInstance } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {
  getGroupList,
  deleteGroupByid,
  addGroupApi,
  updateGroupApi,
  getUserList,
} from "@/api/system";
import { GroupModel } from "@/api/systemModel";
import { deepClone } from "@/util/oauth";

export default defineComponent({
  name: "userManage",
  setup() {
    let search = ref<string>("");
    const searchGroup = (): void => {
      console.log("搜索", search.value);
    };
    // 穿梭框
    let checkedValue = ref([]);
    let data = ref([]);

    // 获取列表
    let groupList = ref<Array<GroupModel>>();
    const getList = async () => {
      const res = await getGroupList();
      groupList.value = res.data as Array<GroupModel>;
    };
    const getUsers = async () => {
      const res = await getUserList();
      data.value = res.data.map((x: any) => {
        return { key: x.userId, label: x.realName };
      });
    };

    // 弹窗
    const rules = ref({
      proGroupName: [
        { required: true, message: "请输入名称", trigger: "blur" },
      ],
    });
    let isShowDialog = ref(false);
    let dialogTitle = ref("新建项目组");
    let dialogForm = ref<GroupModel>({
      id: 0,
      proGroupName: "",
      contentTotal: 0,
      userTotal: 0,
      desc: "",
    });

    const editGroup = (group: GroupModel) => {
      isShowDialog.value = true;
      dialogTitle.value = "编辑项目组";
      checkedValue.value = group.userList?.map((x) => x.userId) as any;
      dialogForm.value = deepClone(group);
    };
    const addGroup = () => {
      dialogForm.value = {
        id: 0,
        proGroupName: "",
        userTotal: 0,
        contentTotal: 0,
      };
      isShowDialog.value = true;
      dialogTitle.value = "新建项目组";
      checkedValue.value = [];
    };

    const { proxy } = getCurrentInstance() as any;
    const save = async (tag: string) => {
      proxy.$refs.ruleForm.validate(async (valid: any) => {
        if (valid) {
          let res = null;
          dialogForm.value.userIdArr = checkedValue.value;
          if (tag == "新建项目组") {
            res = await addGroupApi(dialogForm.value);
          } else {
            res = await updateGroupApi(dialogForm.value);
          }
          if (res != null) {
            if (res.code == 200) {
              ElMessage.success("操作成功");
            } else {
              ElMessage.error("操作失败");
            }
            getList();
            isShowDialog.value = false;
          }
        } else {
          ElMessage.error("请输入信息");
        }
      });
    };

    const cancelDialog = () => {
      isShowDialog.value = false;
      dialogForm.value = {
        id: 0,
        proGroupName: "",
        userTotal: 0,
        contentTotal: 0,
      };
    };

    // 删除
    const deleteConfirm = async (group: GroupModel) => {
      if (group.userTotal == 0) {
        const res = await deleteGroupByid(group.id);
        if (res.code == 200) {
          ElMessage.success("删除成功");
          getList();
        } else {
          ElMessage.error("删除失败");
        }
      } else {
        ElMessageBox.alert("请先移除报告或用户，才能删除项目组", "删除提示", {
          confirmButtonText: "我知道了",
        });
      }
    };

    onMounted(() => {
      getList();
      getUsers();
    });

    return {
      groupList,
      search,
      searchGroup,
      isShowDialog,
      dialogTitle,
      checkedValue,
      data,
      deleteConfirm,
      editGroup,
      dialogForm,
      addGroup,
      cancelDialog,
      save,
      rules,
    };
  },
});
